// // import external dependencies
import 'jquery';
import L from 'leaflet';
import 'leaflet.markercluster';

// const iconRetinaUrl = 'https://nimes-tourisme.com/app/uploads/leaflet/layers-2x.png';
const iconRetinaUrl = 'https://nimes-tourisme.com/app/uploads/leaflet/layers-2x.png';
// const iconUrl = '/resources/assets/images/marker-icon.png';
const iconUrl = 'https://nimes-tourisme.com/app/uploads/leaflet/marker-icon.png';
// const shadowUrl = '/resources/assets/images/marker-shadow.png';
const shadowUrl = 'https://nimes-tourisme.com/app/uploads/leaflet/marker-shadow.png';
const iconDefault = L.icon({
  iconRetinaUrl,
  iconUrl,
  shadowUrl,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  tooltipAnchor: [16, -28],
  shadowSize: [41, 41],
});
L.Marker.prototype.options.icon = iconDefault;

// Import everything from autoload
import './autoload/add-to-calendar.js'; import './autoload/AjaxEvents.js'; import './autoload/AjaxNews.js'; import './autoload/AjaxRefs.js'

// import local dependencies
import Router from './util/Router';
import common from './routes/common';
import home from './routes/home';
import aboutUs from './routes/about';

/** Populate Router instance with DOM routes */
const routes = new Router({
  // All pages
  common,
  // Home page
  home,
  // About Us page, note the change from about-us to aboutUs.
  aboutUs,
});

// Load Events
jQuery(document).ready(() => routes.loadEvents());
